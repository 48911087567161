import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatMerchantTypeI18n } from "../../helpers/OrderHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessages } from "../../reducers/LocalizationReducer";
import { MerchantTypeForFilter } from "../../constants/MerchantTypes";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => {
        const x: DataListFilter = props.filter;

        return {
          merchantType: x.getValue("merchantType") || null,
        };
      })
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValue("merchantType", values.merchantType);
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    form: "CustomerFilterForm",
    enableReinitialize: true,
  }),
  pureComponent(fp.pick(["dirty", "initialValues"])),
);

CustomerFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  reset: PropTypes.func,
  change: PropTypes.func,
  dirty: PropTypes.bool,
  filter: PropTypes.instanceOf(DataListFilter),
  submitting: PropTypes.bool,
  i18n: PropTypes.instanceOf(Map),
};

function CustomerFilterForm(props) {
  return (
    <FlexBox align="center" container={8} element={<form autoComplete="off" />}>
      <FlexBox gutter={8} flex={true}>
        <FlexBox flex={true} direction="column">
          <Card>
            <CardContent>
              <PageLoading isLoading={props.submitting} />

              <FlexBox flex={true} direction="column">
                <FormSelectField
                  name="merchantType"
                  autoWidth={true}
                  options={MerchantTypeForFilter}
                  formatOption={x => formatMerchantTypeI18n(x, props.i18n)}
                  label={props.i18n.get("client_type", "Client Type")}
                />
              </FlexBox>
            </CardContent>

            <CardActions>
              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true} justify="flex-end">
                  <Button onClick={props.onDismiss}>
                    {props.i18n.get("dismiss", "Dismiss")}
                  </Button>
                  <Button
                    onClick={() => {
                      fp.keys(props.initialValues).forEach(key =>
                        props.change(key, null),
                      );
                    }}
                  >
                    {props.i18n.get("clear", "Clear")}
                  </Button>
                  {props.dirty && (
                    <Button onClick={props.reset}>
                      {props.i18n.get("reset", "Reset")}
                    </Button>
                  )}
                  <Button onClick={props.handleSubmit}>
                    {props.i18n.get("submit", "Submit")}
                  </Button>
                </FlexBox>
              </FlexBox>
            </CardActions>
          </Card>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(CustomerFilterForm);
