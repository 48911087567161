import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import PageLoading from "../ui-core/PageLoading";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "AdminCustomerCompanyEndPointForm",
    enableReinitialize: true,
  }),
);

AdminCustomerCompanyEndPointForm.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminCustomerCompanyEndPointForm(props) {
  const { getLocalisationMessage } = props;

  return (
    <Card>
      <PageLoading isLoading={props.submitting} />
      <CardHeader
        subheader={getLocalisationMessage(
          "company_endpoint",
          "Company Endpoint",
        )}
      />

      <CardContent>
        <FlexBox direction="column" gutter={16}>
          <FlexBox>
            <FormTextField
              name="endpoint"
              fullWidth={true}
              label={getLocalisationMessage("end_point", "End Point")}
            />
          </FlexBox>
          <FlexBox gutter={16}>
            <FlexBox flex={true}>
              <FormTextField
                name="key1"
                fullWidth={true}
                label={getLocalisationMessage("key1")}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormTextField
                name="key2"
                fullWidth={true}
                label={getLocalisationMessage("key2")}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </CardContent>

      <CardActions>
        <form onSubmit={props.handleSubmit}>
          <Button variant="text" type="submit">
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </form>
      </CardActions>
    </Card>
  );
}

export default enhancer(AdminCustomerCompanyEndPointForm);
