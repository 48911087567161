import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormUserAutoComplete from "../form/FormUserAutoComplete";
import PageLoading from "../ui-core/PageLoading";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getSalesPersonePredictions } from "../../api/admin/AdminUserApi";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "AdminCustomerSalesPersonForm",
    enableReinitialize: true,
  }),
);

AdminCustomerSalesPersonForm.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminCustomerSalesPersonForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <form onSubmit={props.handleSubmit}>
      <PageLoading isLoading={props.submitting} />

      <Card>
        <CardHeader
          subheader={getLocalisationMessage("sales_person", "Sales Person")}
        />

        <CardContent>
          <FormUserAutoComplete
            fullWidth={true}
            name="salesPerson"
            hintText={getLocalisationMessage(
              "type_to_search",
              "Type To Search...",
            )}
            getUserPredictions={getSalesPersonePredictions}
          />
        </CardContent>

        <CardActions>
          <Button variant="text" type="submit">
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

export default enhancer(AdminCustomerSalesPersonForm);
