import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const COMPANY_ITEM_URL = `${API_ROOT_URL}/admin/company/%s`;
const CUSTOMER_COMPANY_URL = `${API_ROOT_URL}/admin/customer/company`;
const CUSTOMER_COMPANY_ITEM_URL = `${API_ROOT_URL}/admin/customer/company/%s`;

const COMPANY_FEE_SETTINGS = `${API_ROOT_URL}/admin/company/fee_settings`;
const COMPANY_FINANCE_SETTINGS = `${
  API_ROOT_URL
}/admin/company/finance_settings`;

const COMPANY_ITEM_FEE_SETTINGS = `${
  API_ROOT_URL
}/admin/company/%s/fee_settings`;
const COMPANY_ITEM_FINANCE_SETTINGS = `${
  API_ROOT_URL
}/admin/company/%s/finance_settings`;
const COMPANY_ITEM_COURIER_TYPE_SETTINGS = `${
  API_ROOT_URL
}/admin/customer/%s/auto_dispatch_settings`;
const UPDATE_COMPANY_END_POINT_SETTINGS = `${
  API_ROOT_URL
}/admin/company/endpoint`;
const GET_COMPANY_END_POINT_SETTINGS = `${
  API_ROOT_URL
}/admin/company/%s/endpoint`;

export const getCompany = id => api.getStream(sprintf(COMPANY_ITEM_URL, id));
export const getCustomerCompany = id =>
  api.getStream(sprintf(CUSTOMER_COMPANY_ITEM_URL, id));

type CustomerCompany = {
  id: number,
  name: string,
  customerId: number,
  public_orders: boolean,
  contract: { id: number, name: string },
};

export const updateCustomerCompany = (values: CustomerCompany) =>
  api.post(CUSTOMER_COMPANY_URL, { body: values });

export const getCompanyFeeSettings = id =>
  api.getStream(sprintf(COMPANY_ITEM_FEE_SETTINGS, id));

type CompanyCourierTypesSettings = {
  company_courier_types: string,
};

export const getCompanyCourierTypesSettings = id =>
  api.getStream(sprintf(COMPANY_ITEM_COURIER_TYPE_SETTINGS, id));

export const getCompanyEndPointSettings = id =>
  api.getStream(sprintf(GET_COMPANY_END_POINT_SETTINGS, id));

export const updateCompanyEndPointSettings = values =>
  api.post(UPDATE_COMPANY_END_POINT_SETTINGS, { body: values });

export const updateCompanyCourierTypesSettings = (
  id,
  values: CompanyCourierTypesSettings,
) =>
  api.post(sprintf(COMPANY_ITEM_COURIER_TYPE_SETTINGS, id), { body: values });

type CompanyFeeSettings = {
  id: number,
  apply_fee: boolean,
  apply_from_global_settings: boolean,
  company: { id: boolean, name: string },
  fee: number,
  fee_type: "COD" | "BANK_TRANSFER",
  fee_definition_type: "FIXED" | "PERCENTAGE",
};

export const updateCompanyFeeSettings = (values: CompanyFeeSettings[]) =>
  api.post(COMPANY_FEE_SETTINGS, { body: values });

export const getCompanyFinanceSettings = id =>
  api.getStream(sprintf(COMPANY_ITEM_FINANCE_SETTINGS, id));

type CompanyFinanceSettings = {
  id: number,
  unlimited: boolean,
  credit_limit: number,
  company: { id: number, name: string },
  company_bill_type: "INVOICED" | "DEDUCTIBLE",
  payment_type: "CREDIT_BALANCE" | "CASH_ONLINE" | "CASH_ONLINE_CREDIT_BALANCE",
};

export const updateCompanyFinanceSettings = (values: CompanyFinanceSettings) =>
  api.post(COMPANY_FINANCE_SETTINGS, { body: values });
