import React from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card, CardHeader, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import PageLoading from "../ui-core/PageLoading";
import FileUploadButton from "../deprecated/FileUploadButton";
import { getMessage } from "../../reducers/LocalizationReducer";

const getContractUrl = fp.get("contract.url");

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "AdminCustomerContractForm",
    enableReinitialize: true,
  }),
);

AdminCustomerContractForm.propTypes = {
  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
};

function AdminCustomerContractForm(props) {
  const contractUrl = getContractUrl(props.initialValues);

  return (
    <Card>
      <PageLoading isLoading={props.submitting} />

      <CardHeader
        subheader={props.getLocalisationMessage("contract", "Contract")}
      />

      <CardActions>
        {Boolean(contractUrl) && (
          <Button
            variant="text"
            target="_blank"
            href={contractUrl}
            component="a"
          >
            {props.getLocalisationMessage(
              "download_contract",
              "Download Contract",
            )}
          </Button>
        )}

        <FileUploadButton
          onChange={fp.flow(
            fp.get("data"),
            value => props.change("contract", value),
            () => props.handleSubmit(),
          )}
        >
          {props.getLocalisationMessage(
            "upload_new_contract",
            "Upload New Contract",
          )}
        </FileUploadButton>
      </CardActions>
    </Card>
  );
}

export default enhancer(AdminCustomerContractForm);
