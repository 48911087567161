import { Observable } from "rxjs";
import React from "react";
import Immutable, { Set } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AdminCustomerItemDetailsContainer from "./AdminCustomerItemDetailsContainer";
import AdminCustomerItemCommunicationContainer from "./AdminCustomerItemCommunicationContainer";
import AdminCustomerItemFinanceSettingsContainer from "./AdminCustomerItemFinanceSettingsContainer";
import { isEqualData } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { CUSTOMER_ITEM_URL } from "../../constants/AdminPathConstants";
import { getMarketplace } from "../../api/shared/MarketplaceApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import RoutingTabs from "../../components/deprecated/RoutingTabs";

const DETAILS = "details";
const FINANCE = "finance";
const PACKAGES = "packages";
const COMMUNICATION = "communication";
const CALL_TIMING = "call-timing";

const tabTypes = Set.of(DETAILS, FINANCE, PACKAGES, COMMUNICATION, CALL_TIMING);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const marketplaceIdStream = getMarketplace()
      .takeLast(1)
      .catch(() => Observable.of({}))
      .map(
        fp.flow(fp.get("payload.data"), fp.toPlainObject, Immutable.fromJS, x =>
          x.get("marketplace_id"),
        ),
      );

    return propsStream
      .combineLatest(marketplaceIdStream, (props, marketplaceId) => ({
        ...props,
        marketplaceId,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminCustomerItem.propTypes = {
  params: PropTypes.object,
  location: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
};

function AdminCustomerItem(props) {
  const { params, getLocalisationMessage } = props;

  const tabs = [
    {
      label: getLocalisationMessage("details", "Details"),
      value: `${CUSTOMER_ITEM_URL}${params.customerId}/${DETAILS}`,
    },
    {
      label: getLocalisationMessage("finance_settings", "Finance Settings"),
      value: `${CUSTOMER_ITEM_URL}${params.customerId}/${FINANCE}`,
    },
    {
      label: getLocalisationMessage("notification_settings"),
      value: `${CUSTOMER_ITEM_URL}${params.customerId}/${COMMUNICATION}`,
    },
  ];

  return (
    <AdminAppLayout title={getLocalisationMessage("customers", "Customers")}>
      <Redirect
        when={!tabTypes.has(params.tab)}
        to={`${CUSTOMER_ITEM_URL}${params.customerId}/${DETAILS}`}
      />

      <RoutingTabs
        width={300}
        mergeQuery={false}
        omitQueryParams={["page"]}
        tabs={tabs}
      />

      {params.tab === DETAILS && (
        <AdminCustomerItemDetailsContainer params={params} />
      )}
      {params.tab === FINANCE && (
        <AdminCustomerItemFinanceSettingsContainer
          params={params}
          location={props.location}
        />
      )}
      {params.tab === COMMUNICATION && (
        <AdminCustomerItemCommunicationContainer
          params={params}
          location={props.location}
        />
      )}
    </AdminAppLayout>
  );
}

export default enhancer(AdminCustomerItem);
