import React, { useState } from "react";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector, reduxForm } from "redux-form";
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FlexBox, { JUSTIFY_CENTER } from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import {
  DATE_URL_FORMAT,
  formatDate,
  formatText,
} from "../../helpers/FormatUtils";
import { formatMerchantType } from "../../helpers/OrderHelper";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import MerchantTypes, { MERCHANTS } from "../../constants/MerchantTypes";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import PostcodeAutoCompleteForOrder from "../form/PostcodeAutoCompleteForOrder";
import { getValue } from "../../helpers/DataUtils";
import MuiPhoneNumber from "material-ui-phone-number-uzpost";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import {
  Done,
  KeyboardArrowLeft,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { CUSTOMER_LIST_ALL_URL } from "../../constants/AdminPathConstants";
import FormDateField from "../form/FormDateField";
import FormCheckbox from "../form/FormCheckbox";

const Statuses = OrderedSet([ACTIVE, INACTIVE]);
const valueSelector = formValueSelector("AdminCustomerForm");
const customerGenderOptions = OrderedSet.of("male", "female");

const enhancer = compose(
  connect(state => ({
    isRTL: getIsRTL(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "AdminCustomerForm",
    enableReinitialize: true,
    validate: (values, { getLocalisationMessage, edit }) => ({
      login: !values.login && getLocalisationMessage("this_field_is_required"),
      password:
        !edit &&
        !values.password &&
        getLocalisationMessage("this_field_is_required"),
      firstName:
        !values.firstName && getLocalisationMessage("this_field_is_required"),
      phone:
        values.phone &&
        values.phone.length < 8 &&
        getLocalisationMessage("this_field_is_required"),
      merchantType:
        fp.isEmpty(values.merchantType) &&
        getLocalisationMessage("select_merchant_type", "Select Merchant Type"),
    }),
  }),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
  connect(state => ({
    values: valueSelector(
      state,
      "jurisdiction",
      "postcode",
      "phone",
      "merchantType",
    ),
  })),
);

const AdminCustomerForm = ({
  getLocalisationMessage,
  handleSubmit,
  submitting,
  values,
  change,
  setLocation,
}) => {
  const [postcodeOptions, setPostcodeOptions] = useState([]);
  const { postcode, jurisdiction, phone } = values;
  const jurisdictionId = getValue(jurisdiction, "id");
  const countryCode = getValue(jurisdiction, "country_code");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <Card>
      <PageLoading isLoading={submitting} />
      <form autoComplete="off" onSubmit={handleSubmit}>
        <CardContent>
          <FlexBox container={8} direction="column">
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true}>
                <FormTextField
                  name="firstName"
                  margin="normal"
                  fullWidth={true}
                  label={`${getLocalisationMessage("first_name")}*`}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <FormTextField
                  name="lastName"
                  margin="normal"
                  fullWidth={true}
                  label={getLocalisationMessage("last_name")}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <MuiPhoneNumber
                  disableAreaCodes={true}
                  margin="normal"
                  isValid={value => value.length > 8}
                  defaultCountry={
                    countryCode ? countryCode.toLowerCase() : "uz"
                  }
                  name="phone"
                  value={phone || ""}
                  onChange={v => {
                    change("phone", v);
                    change("login", v);
                  }}
                  variant={OUTLINED}
                  size="small"
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "phone_number",
                    "Phone Number",
                  )}*`}
                />
                <FormTextField
                  style={{ display: "none" }}
                  name="phone"
                  label={getLocalisationMessage("phone_number", "Phone Number")}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true}>
                <FormTextField
                  disabled={true}
                  name="login"
                  margin="normal"
                  fullWidth={true}
                  label={`${getLocalisationMessage("login")}*`}
                  readOnly={true}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <FormTextField
                  name="email"
                  margin="normal"
                  fullWidth={true}
                  label={getLocalisationMessage("email")}
                />
              </FlexBox>
              <FlexBox flex={true} align="center" style={{ marginTop: 5 }}>
                <FormTextField
                  name="password"
                  type={showPassword ? "text" : "password"}
                  fullWidth={true}
                  label={`${getLocalisationMessage("password", "Password")} *`}
                  withOutInputProps={false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox
              gutter={8}
              flex={true}
              style={{ marginTop: "1rem", marginBottom: ".25rem" }}
            >
              <FlexBox flex={true} style={{ gap: 13 }}>
                <FlexBox flex={true}>
                  <FormSelectField
                    fullWidth={true}
                    name="merchantType"
                    options={MerchantTypes}
                    formatOption={x =>
                      formatMerchantType(x, getLocalisationMessage)
                    }
                    label={`${getLocalisationMessage(
                      "client_type",
                      "Client Type",
                    )}*`}
                    onChange={() => change("canCreateCodOrder", false)}
                  />
                </FlexBox>
                {values.merchantType === MERCHANTS && (
                  <FlexBox flex={true}>
                    <FormCheckbox
                      name="canCreateCodOrder"
                      label={getLocalisationMessage(
                        "can_create_order_with_cod",
                        "Can create order with COD",
                      )}
                    />
                  </FlexBox>
                )}
              </FlexBox>
              <FlexBox flex={true} style={{ gap: 13 }}>
                <FormSelectField
                  fullWidth={true}
                  name="status"
                  autoWidth={true}
                  options={Statuses}
                  formatOption={x => getLocalisationMessage(x) || formatText(x)}
                  label={getLocalisationMessage("status")}
                />
                <FormSelectField
                  fullWidth={true}
                  name="source"
                  autoWidth={true}
                  options={["UZPOST", "HYBRID"]}
                  formatOption={x => getLocalisationMessage(x) || formatText(x)}
                  label={getLocalisationMessage("source")}
                />
              </FlexBox>
              <FlexBox flex={true} style={{ gap: 13 }}>
                <FormSelectField
                  fullWidth={true}
                  name="gender"
                  autoWidth={true}
                  options={customerGenderOptions}
                  formatOption={x => getLocalisationMessage(x) || formatText(x)}
                  label={getLocalisationMessage("gender")}
                />
                <FormDateField
                  name="birthday"
                  fullWidth={true}
                  formatValue={DATE_URL_FORMAT}
                  formatDate={date => formatDate(date, DATE_URL_FORMAT)}
                  hintText={getLocalisationMessage("birthday", "Birthday")}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true} style={{ gap: 13 }}>
                <FormJMAutoComplete
                  name="jurisdiction"
                  margin="normal"
                  levelIds={[3]}
                  label={getLocalisationMessage(
                    "jurisdiction_to",
                    "Jurisdiction To",
                  )}
                  fullWidth={true}
                  renderOption={option => (
                    <FlexBox direction="column">
                      <span>{option.name}</span>
                      <span
                        style={{
                          fontSize: ".8rem",
                          fontStyle: "italic",
                        }}
                      >
                        {option.value.hierarchy.map((v, i, arr) =>
                          i === arr.length - 1 ? v.name : `${v.name} > `,
                        )}
                      </span>
                    </FlexBox>
                  )}
                />

                <PostcodeAutoCompleteForOrder
                  immediatelyShowError={postcode}
                  name="postcode"
                  margin="normal"
                  fullWidth={true}
                  jurisdictionId={jurisdictionId}
                  jurisdiction={jurisdiction}
                  disabled={!jurisdictionId}
                  postcodes={postcodeOptions}
                  getPostcodeOptions={v => setPostcodeOptions(v)}
                  // onChange={(e, v) => setValidationLevel(v, "PVLevel")}
                  label={getLocalisationMessage("index", "Index")}
                  hintText={getLocalisationMessage("type_to_search")}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormTextField
                  name="address.address"
                  margin="normal"
                  fullWidth={true}
                  label={getLocalisationMessage("address")}
                />
              </FlexBox>

              <FlexBox flex={true} justify="center" align="center">
                <FormCheckbox
                  name="verificationRequired"
                  label={getLocalisationMessage(
                    "face_id_verification",
                    "Delivery verification with FACE ID",
                  )}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </CardContent>

        <CardActions>
          <FlexBox
            style={{ marginBottom: "1rem" }}
            flex={true}
            justify={JUSTIFY_CENTER}
          >
            <CustomButton
              style={{ marginRight: ".5rem" }}
              onClick={() => setLocation(CUSTOMER_LIST_ALL_URL)}
              startIcon={<KeyboardArrowLeft />}
              variant={CONTAINED}
              color={SECONDARY}
            >
              {getLocalisationMessage("back", "Back")}
            </CustomButton>
            <CustomButton
              style={{ marginLeft: ".5rem" }}
              variant={CONTAINED}
              color={SECONDARY}
              endIcon={<Done />}
              type="submit"
              onClick={handleSubmit}
            >
              {getLocalisationMessage("save")}
            </CustomButton>
          </FlexBox>
        </CardActions>
      </form>
    </Card>
  );
};
AdminCustomerForm.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  change: PropTypes.func,
  setLocation: PropTypes.func,
  edit: PropTypes.func,
};
export default enhancer(AdminCustomerForm);
