import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UploadButton from "./UploadButton";
import { uploadFileStream } from "../../actions/FileUploadActions";

const enhancer = compose(connect(null, { uploadFileStream }));

FileUploadButton.propTypes = {
  sheet: PropTypes.object,
  progress: PropTypes.number,
  label: PropTypes.node,
  onChange: PropTypes.func,
};

function FileUploadButton(props) {
  return <UploadButton {...props} />;
}

export default enhancer(FileUploadButton);
