import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import Immutable, { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { COD, OVERWEIGHT, BANK_TRANSFER } from "../../constants/FinanceFeeType";
import {
  getCompany,
  getCustomerCompany,
  getCompanyFeeSettings,
  updateCustomerCompany,
  updateCompanyFeeSettings,
  getCompanyFinanceSettings,
  getCompanyEndPointSettings,
  updateCompanyFinanceSettings,
  updateCompanyEndPointSettings,
  getCompanyCourierTypesSettings,
  updateCompanyCourierTypesSettings,
} from "../../api/admin/AdminCompanyApi";
import {
  getSalesPerson,
  getContractOwner,
  updateSalesPerson,
  updateContractOwner,
} from "../../api/admin/AdminCustomerApi";
import { getMarketplace } from "../../api/shared/MarketplaceApi";
import AdminCustomerFeesForm from "../../components/admin/AdminCustomerFeesForm";
import AdminCustomerCompanyForm from "../../components/admin/AdminCustomerCompanyForm";
import AdminCustomerContractForm from "../../components/admin/AdminCustomerContractForm";
import AdminCustomerPaymentsForm from "../../components/admin/AdminCustomerPaymentsForm";
import AdminCustomerSalesPersonForm from "../../components/admin/AdminCustomerSalesPersonForm";
import AdminCustomerCourierTypesForm from "../../components/admin/AdminCustomerCourierTypesForm";
import AdminCustomerContractOwnerForm from "../../components/admin/AdminCustomerContractOwnerForm";
import AdminCustomerCompanyEndPointForm from "../../components/admin/AdminCustomerCompanyEndPointForm";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import { ROLE_SUPER_ADMIN } from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";

const valueSelector = formValueSelector("AdminCustomerCompanyForm");

const getCODFeeSettings = fp.flow(fp.get(COD), fp.defaults({ feeType: COD }));
const getOverweightFeeSettings = fp.flow(
  fp.get(OVERWEIGHT),
  fp.defaults({ feeType: OVERWEIGHT }),
);
const getBankTransferFeeSettings = fp.flow(
  fp.get(BANK_TRANSFER),
  fp.defaults({ feeType: BANK_TRANSFER }),
);

const enhancer = compose(
  useSheet({
    root: { width: "640px", maxWidth: "640px", margin: "12px auto" },
  }),
  connect(
    (state) => ({
      companyId: valueSelector(state, "id"),
      roles: getUser(state).get("roles") || [],
      i18n: getMessages(state),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream((propsStream) => {
    const { handler: onRequestRefresh, stream: onRequestRefreshStream } =
      createEventHandler();

    const marketplaceStream = getMarketplace()
      .takeLast(1)
      .catch(() => Observable.of({}))
      .map(fp.flow(fp.get("payload.data"), fp.toPlainObject, Immutable.fromJS));

    const customerIdStream = propsStream
      .map(fp.flow(fp.get("params.customerId"), fp.toInteger))
      .distinctUntilChanged();

    const isSuperAdminStream = propsStream
      .distinctUntilChanged(isEqualData)
      .map((props) => hasRole(props.roles, ROLE_SUPER_ADMIN))
      .distinctUntilChanged(isEqualData);

    const salesPersonStream = customerIdStream
      .switchMap((customerId) =>
        getSalesPerson(customerId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(() => Observable.of({})),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update(
            "payload",
            fp.flow(fp.get("data"), fp.toPlainObject, (sales) => ({
              salesPerson: { id: sales.sales_id },
            })),
          ),
        ),
      )
      .distinctUntilChanged(isEqualData)
      .share();

    const customerCompanyStream = customerIdStream
      .switchMap((customerId) =>
        getCustomerCompany(customerId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(() => Observable.of({})),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update(
            "payload",
            fp.flow(fp.get("data"), fp.toPlainObject, toCamelCase),
          ),
        ),
      )
      .distinctUntilChanged(isEqualData)
      .share();

    const contractOwnerStream = customerIdStream
      .switchMap((customerId) =>
        getContractOwner(customerId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(() => Observable.of({})),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update(
            "payload",
            fp.flow(fp.get("data"), fp.toPlainObject, (contract) => ({
              contractOwner: { id: contract.owner_id },
            })),
          ),
        ),
      )
      .distinctUntilChanged(isEqualData);

    const companyFeeSettingsResponseStream = customerCompanyStream
      .map(fp.get("payload.id"))
      .distinctUntilChanged()
      .switchMap((id) =>
        id > 0
          ? getCompanyFeeSettings(id)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(() => Observable.of(undefined))
          : Observable.of(undefined),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update(
            "payload",
            fp.flow(fp.get("data"), toCamelCase, fp.keyBy("feeType")),
          ),
        ),
      )
      .distinctUntilChanged(isEqualData);

    const companyFinanceSettingsResponseStream = customerCompanyStream
      .map(fp.get("payload.id"))
      .distinctUntilChanged()
      .switchMap((id) =>
        id > 0
          ? getCompanyFinanceSettings(id)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(() => Observable.of(undefined))
          : Observable.of(undefined),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), toCamelCase)),
        ),
      )
      .distinctUntilChanged(isEqualData);

    const companyCourierSettingsResponseStream = customerIdStream
      .switchMap((customerId) =>
        customerId > 0
          ? getCompanyCourierTypesSettings(customerId)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(() => Observable.of(undefined))
          : Observable.of(undefined),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update(
            "payload",
            fp.flow(fp.get("data"), (selectedCouriers) => {
              let couriers = Map();

              if (!_.isEmpty(selectedCouriers)) {
                selectedCouriers.forEach((item) => {
                  couriers = couriers.set(item, "active");
                });
              }

              return {
                courierTypes: couriers.toJS(),
              };
            }),
          ),
        ),
      )
      .distinctUntilChanged(isEqualData);

    const companyEndPointResponseStream = customerCompanyStream
      .map(fp.get("payload.id"))
      .distinctUntilChanged()
      .switchMap((id) =>
        id > 0
          ? getCompanyEndPointSettings(id)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(() => Observable.of(undefined))
          : Observable.of(undefined),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), toCamelCase)),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        marketplaceStream,
        salesPersonStream,
        contractOwnerStream,
        customerCompanyStream,
        companyFeeSettingsResponseStream,
        companyFinanceSettingsResponseStream,
        companyCourierSettingsResponseStream,
        isSuperAdminStream,
        companyEndPointResponseStream,
        (
          props,
          marketplace,
          salesPersonResponse,
          contractOwnerResponse,
          customerCompanyResponse,
          companyFeeSettingsResponse,
          companyFinanceSettingsResponse,
          companyCourierSettingsResponse,
          isSuperAdmin,
          companyEndPointResponse,
        ) => ({
          ...props,

          onRequestRefresh,
          isSuperAdmin,
          marketplace,
          contractOwner: contractOwnerResponse.payload,
          contractOwnerPending: contractOwnerResponse.pending,
          customerCompany: customerCompanyResponse.payload,
          customerCompanyPending: customerCompanyResponse.pending,

          salesPerson: salesPersonResponse.payload,
          salesPersonPending: salesPersonResponse.pending,

          companyFeeSettings: companyFeeSettingsResponse.payload,
          companyFeeSettingsPending: companyFeeSettingsResponse.pending,
          companyFinanceSettings: companyFinanceSettingsResponse.payload,
          companyFinanceSettingsPending: companyFinanceSettingsResponse.pending,
          companyCourierSettings:
            companyCourierSettingsResponse.payload.courierTypes,
          companyCourierSettingsPending: companyCourierSettingsResponse.pending,

          companyEndPoint: companyEndPointResponse.payload,
          companyEndPointPending: companyEndPointResponse.pending,
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminCustomerItemFinanceSettingsContainer.propTypes = {
  classes: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  companyId: PropTypes.number,
  customerCompany: PropTypes.object,
  customerCompanyPending: PropTypes.bool,
  contractOwner: PropTypes.object,
  contractOwnerPending: PropTypes.bool,
  companyFeeSettings: PropTypes.object,
  marketplace: PropTypes.object,
  companyFeeSettingsPending: PropTypes.bool,
  companyFinanceSettings: PropTypes.object,
  companyFinanceSettingsPending: PropTypes.bool,
  companyCourierSettings: PropTypes.object,
  companyEndPoint: PropTypes.object,
  salesPerson: PropTypes.object,
  isSuperAdmin: PropTypes.bool,
  i18n: PropTypes.instanceOf(Map),
  params: PropTypes.object.isRequired,
};

function AdminCustomerItemFinanceSettingsContainer(props) {
  const { classes, i18n } = props;
  const customerId = fp.toFinite(props.params.customerId);

  const hasCompany = Boolean(
    props.customerCompany.id === props.companyId && props.companyId > 0,
  );

  const isCustom = props.marketplace && props.marketplace.get("custom");
  const hidden = false;

  const showAutoDispatch = !isCustom
    ? Boolean(hasCompany && props.isSuperAdmin)
    : hasCompany;

  return (
    <FlexBox
      className={classes.root}
      container={8}
      direction="column"
      flex="none"
    >
      <PageLoading
        isLoading={
          props.contractOwnerPending ||
          props.customerCompanyPending ||
          props.companyFeeSettingsPending ||
          props.companyFinanceSettingsPending
        }
      />

      <FlexBox gutter={8} direction="column">
        <FlexBox direction="column">
          <AdminCustomerContractOwnerForm
            initialValues={props.contractOwner}
            onSubmit={(values) =>
              updateContractOwner({
                customer_id: customerId,
                owner_id: values.contractOwner.id,
              }).catch(
                fp.flow(
                  fp.toPlainObject,
                  fp.update(
                    "response",
                    fp.flow(fp.get("owner_id"), (contractOwner) => ({
                      contractOwner,
                    })),
                  ),
                  ResponseError.throw,
                ),
              )
            }
            onSubmitSuccess={() => {
              props.onRequestRefresh();
              props.showSuccessMessage(i18n.get("successfully_saved"));
            }}
            onSubmitFail={props.showErrorMessage}
          />
        </FlexBox>

        <FlexBox direction="column">
          <AdminCustomerSalesPersonForm
            initialValues={props.salesPerson}
            onSubmit={(values) =>
              updateSalesPerson({
                customer_id: customerId,
                sales_id: values.salesPerson.id,
              }).catch(ResponseError.throw)
            }
            onSubmitSuccess={() => {
              props.onRequestRefresh();
              props.showSuccessMessage(i18n.get("successfully_saved"));
            }}
            onSubmitFail={props.showErrorMessage}
          />
        </FlexBox>

        <FlexBox direction="column">
          <AdminCustomerCompanyForm
            initialValues={props.customerCompany}
            onSubmit={(company) => {
              const request =
                company.id > 0
                  ? getCompany(company.id)
                      .toPromise()
                      .then(fp.get("payload.data"))
                  : Promise.resolve(company).then(
                      fp.update("publicOrders", Boolean),
                    );

              return request
                .then(
                  fp.flow(
                    fp.set("customerId", customerId),
                    toSnakeCase,
                    updateCustomerCompany,
                  ),
                )
                .catch(ResponseError.throw);
            }}
            onSubmitSuccess={() => {
              props.onRequestRefresh();
              props.showSuccessMessage(i18n.get("company_saved"));
            }}
            onSubmitFail={props.showErrorMessage}
          />
        </FlexBox>

        {hasCompany && (
          <FlexBox direction="column">
            <AdminCustomerContractForm
              initialValues={props.customerCompany}
              onSubmit={fp.flow(
                fp.set("customerId", customerId),
                toSnakeCase,
                updateCustomerCompany,
                (request) => request.catch(ResponseError.throw),
              )}
              onSubmitSuccess={() => {
                props.onRequestRefresh();
                props.showSuccessMessage(
                  i18n.get("new_contract_uploaded" || "New Contract Uploaded"),
                );
              }}
              onSubmitFail={props.showErrorMessage}
            />
          </FlexBox>
        )}

        {hasCompany && (
          <FlexBox direction="column">
            <AdminCustomerPaymentsForm
              initialValues={{
                unlimited: true,
                ...props.companyFinanceSettings,
              }}
              onSubmit={fp.flow(
                toSnakeCase,
                fp.set("company.id", props.companyId),
                updateCompanyFinanceSettings,
                (request) => request.catch(ResponseError.throw),
              )}
              onSubmitSuccess={() => {
                props.onRequestRefresh();
                props.showSuccessMessage(i18n.get("payment_settings_updated"));
              }}
              onSubmitFail={props.showErrorMessage}
            />
          </FlexBox>
        )}

        {hidden && hasCompany && (
          <FlexBox direction="column">
            <AdminCustomerFeesForm
              title={i18n.get("cod_fee", "COD Free")}
              form="AdminCustomerFeesFormCOD"
              initialValues={getCODFeeSettings(props.companyFeeSettings)}
              onSubmit={fp.flow(
                toSnakeCase,
                fp.set("company.id", props.companyId),
                (settings) => ({
                  ...props.companyFeeSettings,
                  [COD]: settings,
                }),
                fp.toArray,
                toSnakeCase,
                updateCompanyFeeSettings,
                (request) => request.catch(ResponseError.throw),
              )}
              onSubmitSuccess={() => {
                props.onRequestRefresh();
                props.showSuccessMessage("bank_transfer_fees_updated");
              }}
              onSubmitFail={props.showErrorMessage}
            />
          </FlexBox>
        )}

        {hidden && showAutoDispatch && (
          <FlexBox direction="column">
            <AdminCustomerCourierTypesForm
              title={i18n.get("auto_dispatch_settings")}
              isOverweight={true}
              form="AdminCustomerCourierTypesForm"
              initialValues={{ courierTypes: props.companyCourierSettings }}
              onSubmit={(values) =>
                updateCompanyCourierTypesSettings(customerId, values).catch(
                  ResponseError.throw,
                )
              }
              onSubmitSuccess={() => {
                props.onRequestRefresh();
                props.showSuccessMessage(i18n.get("settings_are_updated"));
              }}
              onSubmitFail={props.showErrorMessage}
            />
          </FlexBox>
        )}

        {hidden && hasCompany && (
          <FlexBox direction="column">
            <AdminCustomerFeesForm
              title={i18n.get("extra_kg_rate_domestic")}
              isOverweight={true}
              form="AdminCustomerFeesFormOVERWEIGHT"
              initialValues={getOverweightFeeSettings(props.companyFeeSettings)}
              onSubmit={fp.flow(
                toSnakeCase,
                fp.set("company.id", props.companyId),
                (settings) => ({
                  ...props.companyFeeSettings,
                  [OVERWEIGHT]: settings,
                }),
                fp.toArray,
                toSnakeCase,
                updateCompanyFeeSettings,
                (request) => request.catch(ResponseError.throw),
              )}
              onSubmitSuccess={() => {
                props.onRequestRefresh();
                props.showSuccessMessage(
                  i18n.get("overweight_rate_is_updated"),
                );
              }}
              onSubmitFail={props.showErrorMessage}
            />
          </FlexBox>
        )}

        {hasCompany && (
          <FlexBox direction="column">
            <AdminCustomerFeesForm
              title={i18n.get("bank_transfer_fee")}
              form="AdminCustomerFeesFormBankTransfer"
              initialValues={getBankTransferFeeSettings(
                props.companyFeeSettings,
              )}
              onSubmit={fp.flow(
                fp.set("company.id", props.companyId),
                (settings) => ({
                  ...props.companyFeeSettings,
                  [BANK_TRANSFER]: settings,
                }),
                fp.toArray,
                toSnakeCase,
                updateCompanyFeeSettings,
                (request) => request.catch(ResponseError.throw),
              )}
              onSubmitSuccess={() => {
                props.onRequestRefresh();
                props.showSuccessMessage(
                  i18n.get("bank_transfer_fees_updated"),
                );
              }}
              onSubmitFail={props.showErrorMessage}
            />
          </FlexBox>
        )}

        {hasCompany && (
          <FlexBox direction="column">
            <AdminCustomerCompanyEndPointForm
              initialValues={props.companyEndPoint}
              onSubmit={(values) => {
                const data = {
                  company_id: props.companyId,
                  endpoint: values.endpoint,
                  headers: {
                    [values.key1]: values.key2,
                  },
                };
                return updateCompanyEndPointSettings(data).catch(
                  ResponseError.throw,
                );
              }}
              onSubmitSuccess={() => {
                props.onRequestRefresh();
                props.showSuccessMessage(i18n.get("company_end_point_updated"));
              }}
              onSubmitFail={props.showErrorMessage}
            />
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(AdminCustomerItemFinanceSettingsContainer);
