import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormCompanyAutoComplete from "../form/FormCompanyAutoComplete";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  useSheet({ content: { maxWidth: "320px" } }),
  reduxForm({ form: "FindCompanyDialog" }),
);

FindCompanyDialog.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  onChange: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function FindCompanyDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      className={props.classes.content}
    >
      <PageLoading isLoading={props.submitting} />

      <DialogContent
        style={{
          maxWidth: "320px",
        }}
      >
        <FormCompanyAutoComplete
          name="company"
          fullWidth={true}
          hintText={props.getLocalisationMessage(
            "type_to_search",
            "Type To Search...",
          )}
        />
      </DialogContent>
      <DialogActions>
        <div>
          <Button
            label={props.getLocalisationMessage("dismiss")}
            onClick={props.onRequestClose}
          />
          <Button
            label={props.getLocalisationMessage("submit")}
            onClick={props.handleSubmit}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(FindCompanyDialog);
