import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { formatNumber } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { BANK_TRANSFER } from "../../constants/FinanceFeeType";
import FinanceFeeDefinition, {
  FIXED,
  PERCENTAGE,
} from "../../constants/FinanceFeeDefinition";

const formatCODFeeDefinition = (type, getLocalisationMessage) => {
  switch (type) {
    case FIXED:
      return getLocalisationMessage(
        "fixed_fee_per_cod_order",
        "Fixed fee per COD order",
      );
    case PERCENTAGE:
      return getLocalisationMessage(
        "percent_of_the_cod_value",
        "% of the COD value",
      );
    default:
      return type;
  }
};

const formatBankTransferFeeDefinition = (type, getLocalisationMessage) => {
  switch (type) {
    case FIXED:
      return getLocalisationMessage(
        "fixed_fee_per_cod_transfer",
        "Fixed fee per COD transfer",
      );
    case PERCENTAGE:
      return getLocalisationMessage(
        "percent_of_cod_transfer_amount",
        "% of COD transfer amount",
      );
    default:
      return type;
  }
};

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({ enableReinitialize: true }),
  formValues("feeType", "applyFee", "applyFromGlobalSettings"),
);

AdminCustomerFeesForm.propTypes = {
  feeType: PropTypes.string,
  applyFee: PropTypes.bool,
  applyFromGlobalSettings: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  title: PropTypes.node.isRequired,
  isOverweight: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function AdminCustomerFeesForm(props) {
  const { getLocalisationMessage } = props;

  return (
    <form onSubmit={props.handleSubmit}>
      <PageLoading isLoading={props.submitting} />

      <Card>
        <CardHeader subheader={props.title} />

        <CardContent>
          {props.isOverweight ? (
            <FlexBox container={8}>
              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true} direction="column">
                  <FormCheckbox
                    name="applyFee"
                    label={getLocalisationMessage("apply_fee", "Apply Fee")}
                  />
                  {props.applyFee && (
                    <FormTextField
                      name="fee"
                      fullWidth={true}
                      parseOnBlur={formatNumber}
                      label={getLocalisationMessage("fee_amount", "Fee Amount")}
                    />
                  )}
                </FlexBox>
              </FlexBox>
            </FlexBox>
          ) : (
            <FlexBox container={8} direction="column">
              <FlexBox gutter={8}>
                <FlexBox flex={true}>
                  <FormCheckbox
                    name="applyFee"
                    label={getLocalisationMessage("apply_fee", "Apply Fee")}
                  />
                </FlexBox>

                {false && props.applyFee && (
                  <FlexBox flex={true}>
                    <FormCheckbox
                      name="applyFromGlobalSettings"
                      label={getLocalisationMessage(
                        "apply_from_country_fee_settings",
                        "Apply From Country Fee Settings",
                      )}
                    />
                  </FlexBox>
                )}
              </FlexBox>

              {Boolean(props.applyFee && !props.applyFromGlobalSettings) && (
                <FlexBox gutter={8}>
                  <FlexBox flex={true}>
                    <FormSelectField
                      fullWidth={true}
                      name="feeDefinitionType"
                      options={FinanceFeeDefinition}
                      label={getLocalisationMessage(
                        "fee_definition_type",
                        "Fee Definition Type",
                      )}
                      formatOption={
                        props.feeType === BANK_TRANSFER
                          ? type =>
                              formatBankTransferFeeDefinition(
                                type,
                                getLocalisationMessage,
                              )
                          : type =>
                              formatCODFeeDefinition(
                                type,
                                getLocalisationMessage,
                              )
                      }
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTextField
                      name="fee"
                      fullWidth={true}
                      label={getLocalisationMessage(
                        "fee_amount",
                        "Free Amount",
                      )}
                    />
                  </FlexBox>
                </FlexBox>
              )}
            </FlexBox>
          )}
        </CardContent>

        <CardActions>
          <Button variant="text" type="submit">
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

export default enhancer(AdminCustomerFeesForm);
