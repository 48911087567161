import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Card,
  CardContent,
  MenuItem,
  CardHeader,
  Button,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import MenuButtonMore from "../ui-core/MenuButtonMore";
import FindCompanyDialog from "../company-core/FindCompanyDialog";
import { createNotFalsyValidator } from "../../helpers/FormUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({ enableReinitialize: true, form: "AdminCustomerCompanyForm" }),
  formValues("id", "selectCompany"),
);

AdminCustomerCompanyForm.propTypes = {
  id: PropTypes.number,
  selectCompany: PropTypes.bool,
  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminCustomerCompanyForm(props) {
  const existCompany = props.id > 0;
  const { getLocalisationMessage } = props;

  return (
    <form onSubmit={props.handleSubmit}>
      <PageLoading isLoading={props.submitting} />

      <FindCompanyDialog
        open={Boolean(props.selectCompany)}
        onRequestClose={() => props.change("selectCompany", null)}
        onSubmit={values => {
          props.change("id", values.company.id);
          props.change("name", values.company.name);
          props.change("contract", null);
          props.change("publicOrders", false);
          props.change("selectCompany", null);
        }}
      />

      <Card>
        <CardHeader
          subheader={
            existCompany
              ? getLocalisationMessage("company", "Company")
              : getLocalisationMessage("create_company", "Create Company")
          }
        />

        <CardContent>
          <FlexBox>
            <FlexBox flex={true}>
              <FormTextField
                name="name"
                fullWidth={true}
                placeholder={getLocalisationMessage(
                  "company_name",
                  "Company Name",
                )}
                readOnly={existCompany}
                validate={createNotFalsyValidator(
                  getLocalisationMessage(
                    "enter_company_name",
                    "Enter Company Name",
                  ),
                )}
              />
            </FlexBox>

            <FlexBox>
              <MenuButtonMore>
                <MenuItem onClick={() => props.change("selectCompany", true)}>
                  {getLocalisationMessage("select_another", "Select Another")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    props.change("id", null);
                    props.change("name", "");
                    props.change("contract", null);
                    props.change("publicOrders", false);
                  }}
                >
                  {getLocalisationMessage("create_new", "Create New")}
                </MenuItem>
              </MenuButtonMore>
            </FlexBox>
          </FlexBox>

          <br />

          <FormCheckbox
            name="publicOrders"
            label={getLocalisationMessage(
              "orders_visible_for_all_drivers",
              "Orders Visible For All Drivers",
            )}
            disabled={existCompany}
          />
        </CardContent>

        <CardActions>
          <Button variant="text" type="submit">
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

export default enhancer(AdminCustomerCompanyForm);
