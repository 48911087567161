import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withHandlers, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormToggle from "../form/FormToggle";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { isEqualData } from "../../helpers/DataUtils";
import { getMessages } from "../../reducers/LocalizationReducer";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";
import { getServiceTypePredictions } from "../../api/admin/AdminServiceTypesApi";
import { baseFilter } from "../../components/form/FormCourierTypeChips";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  useSheet({
    toggle: { width: "200px", "& > div": { justifyContent: "center" } },
  }),
  mapPropsStream(propsStream => {
    const dataSourceStream = propsStream
      .distinctUntilChanged(isEqualData)
      .switchMap(() =>
        getServiceTypePredictions(baseFilter)
          .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
          .catch(() => Observable.of(List())),
      )
      .distinctUntilChanged(isEqualData)
      .map(v =>
        Map().withMutations(map =>
          v.forEach(item => {
            map.set(item.get("code"), item.get("name"));
          }),
        ),
      )
      .startWith(List());

    return propsStream
      .combineLatest(dataSourceStream, (props, courierTypes) => ({
        ...props,
        courierTypes,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  withHandlers({
    onSubmit: props => values => {
      const companyCourierTypes = [];
      const selectedCourierTypes = fromJS(values.courierTypes);

      props.courierTypes.forEach((text, key) => {
        if (selectedCourierTypes.get(key, null) === "active")
          companyCourierTypes.push(key);
      });

      return props.onSubmit(companyCourierTypes);
    },
  }),
  reduxForm({ enableReinitialize: true }),
);

AdminCustomerCourierTypesForm.propTypes = {
  classes: PropTypes.object,

  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  courierTypes: PropTypes.object,
  title: PropTypes.node.isRequired,
  i18n: PropTypes.instanceOf(Map),
};

function AdminCustomerCourierTypesForm(props) {
  const { i18n } = props;
  return (
    <form onSubmit={props.handleSubmit}>
      <PageLoading isLoading={props.submitting} />

      <Card>
        <CardHeader subheader={props.title} />

        <CardContent>
          <FlexBox direction="column" gutter={8} flex={true}>
            {props.courierTypes.map((text, key) => (
              <FlexBox key={key}>
                <FlexBox gutter={8} style={{ minWidth: "100px" }}>
                  <h6>{text}</h6>
                </FlexBox>
                <FlexBox flex={true}>
                  <FormToggle
                    trueValue={ACTIVE}
                    falseValue={INACTIVE}
                    className={props.classes.toggle}
                    name={`courierTypes.${key}`}
                  />
                </FlexBox>
              </FlexBox>
            ))}
          </FlexBox>
        </CardContent>

        <CardActions>
          <Button variant="text" type="submit">
            {i18n.get("submit", "Submit")}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

export default enhancer(AdminCustomerCourierTypesForm);
