import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FormRadioGroup from "../form/FormRadioGroup";
import Text, { SMALL } from "../ui-core/Text";
import PageLoading from "../ui-core/PageLoading";
import { parseFloat } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import FinanceCompanyBillType, {
  DEDUCTIBLE,
  INVOICED,
} from "../../constants/FinanceCompanyBillType";
import FlexBox from "../ui-core/FlexBox";
import OrderPaymentTypes from "../../constants/OrderPaymentTypes";
import { formatLocalisedPaymentType } from "../../helpers/OrderHelper";
import FormChipAutoComplete from "../form/FormChipAutoComplete";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({ form: "AdminCustomerPaymentsForm", enableReinitialize: true }),
  formValues("unlimited"),
);

const formatFinanceCompanyBillType = (type, getLocalisationMessage) => {
  switch (type) {
    case INVOICED:
      return getLocalisationMessage(
        "invoice_end_of_the_month",
        "Invoice end of the month",
      );
    case DEDUCTIBLE:
      return getLocalisationMessage("deduct_from_cod", "Deduct from COD");
    default:
      return type;
  }
};

AdminCustomerPaymentsForm.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  unlimited: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function AdminCustomerPaymentsForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <Card>
      <PageLoading isLoading={props.submitting} />
      <CardHeader
        subheader={getLocalisationMessage(
          "delivery_charge_payment_options",
          "Delivery Charge Payment Options",
        )}
      />

      <CardContent>
        <FlexBox direction="column" style={{ gap: 13 }}>
          <FormChipAutoComplete
            name="paymentTypes"
            fullWidth={true}
            options={OrderPaymentTypes}
            formatOption={x =>
              formatLocalisedPaymentType(x, getLocalisationMessage)
            }
            hintText={getLocalisationMessage("type_here_to_search")}
            label={getLocalisationMessage("payment_method")}
          />
          {!props.unlimited && (
            <FormTextField
              name="creditLimit"
              fullWidth={true}
              parseOnBlur={parseFloat}
              readOnly={props.unlimited}
              label={getLocalisationMessage("credit_limit", "Credit Limit")}
            />
          )}

          <FormCheckbox
            name="unlimited"
            label={getLocalisationMessage("unlimited", "Unlimited")}
          />
          <div style={{ margin: "1rem 0" }}>
            <FormRadioGroup
              label={getLocalisationMessage("settle_due_service_charge_amount")}
              name="companyBillType"
              options={FinanceCompanyBillType}
              formatOption={type =>
                formatFinanceCompanyBillType(type, getLocalisationMessage)
              }
            />
          </div>
          <Text type={SMALL}>
            {getLocalisationMessage(
              "if_cod_is_not_enough",
              "If Cod Is Not Enough",
            )}
          </Text>
        </FlexBox>
      </CardContent>

      <CardActions>
        <form onSubmit={props.handleSubmit}>
          <Button variant="text" type="submit">
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </form>
      </CardActions>
    </Card>
  );
}

export default enhancer(AdminCustomerPaymentsForm);
