import React from "react";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getCustomer, updateCustomer } from "../../api/admin/AdminCustomerApi";
import {
  getCachedReference,
  getReferencePredictions,
} from "../../api/shared/ReferenceApi";
import AdminCustomerForm from "../../components/admin/AdminCustomerForm";
import PageLoading from "../../components/ui-core/PageLoading";

const enhancer = compose(
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream((propsStream) => {
    const { handler: onRequestRefresh, stream: onRequestRefreshStream } =
      createEventHandler();

    const customerStream = propsStream
      .map(fp.flow(fp.get("params.customerId"), fp.toInteger))
      .distinctUntilChanged()
      .switchMap((customerId) =>
        getCustomer(customerId).repeatWhen(() => onRequestRefreshStream),
      )
      .let(mapObjectResponseStream)
      .map((response) => response.update("payload", toCamelCase))
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(customerStream, (props, customer) => ({
      ...props,
      onRequestRefresh,
      isLoading: customer.get("pending"),
      initialValues: customer.get("payload"),
    }));
  }),
);

AdminCustomerItemDetailsContainer.propTypes = {
  params: PropTypes.object,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  isLoading: PropTypes.bool,
  initialValues: PropTypes.object,
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminCustomerItemDetailsContainer(props) {
  if (props.isLoading) {
    return <PageLoading isLoading={true} />;
  }
  const { getLocalisationMessage } = props;

  return (
    <AdminCustomerForm
      edit={true}
      initialValues={props.initialValues}
      onSubmit={fp.flow(toSnakeCase, (values) =>
        updateCustomer(props.params.customerId, values).catch(
          ResponseError.throw,
        ),
      )}
      onSubmitSuccess={() => {
        props.onRequestRefresh();
        props.showSuccessMessage(
          getLocalisationMessage("successfully_saved", "Successfully Saved"),
        );
      }}
      onSubmitFail={props.showErrorMessage}
      getCachedReference={getCachedReference}
      getReferencePredictions={getReferencePredictions}
    />
  );
}

export default enhancer(AdminCustomerItemDetailsContainer);
